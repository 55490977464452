/*
 * EESY
 * CSS MAIN
 */

* { font-family:'Roboto', sans-serif; color:#08394e; line-height:normal; }
html, body { font-size:14px; padding:0; margin:0; }

#wrap {}

/* AREA */
#area { position:relative; min-width:560px; margin-top:54px; }

/* HEADER */
header { display:flex; justify-content:space-between; align-items:center; position:fixed; top:0; width:100%; background:#f0f0f0; padding:5px 20px; box-sizing:border-box; min-width:560px; z-index:9; }
.header-left {}
.header-left>div { display:inline-block; vertical-align:middle; }
.header-right {}
.header-right>div { display:inline-block; vertical-align:middle; }

/* LOGO */
.eesy-logo { margin:0 -5px 0 0; font-size:0; }
.eesy-logo img { height:32px; width:auto; }

/* EVENTS */
.events { position:relative; padding:7px 10px 7px 15px; background:#fff; border:1px solid #ccc; border-radius:7px; color:#08394e; font-size:15px; cursor:default; }
.events:hover .event-list { display:block; }
.events .event-selected { vertical-align:middle; }
.events .material-icons { margin-left:15px; vertical-align:middle; transition:transform 0.2s ease; }
.events:hover .material-icons { transform:rotate(180deg); }
ul.event-list { display:none; position:absolute; left:-1px; top:38px; margin:0; padding:0; background:#fff; border:1px solid #ccc; box-shadow:0 3px 10px rgba(0,0,0,0.3); }
ul.event-list li { list-style:none; margin:0; padding:0; white-space:nowrap; }
ul.event-list li a { display:block; padding:10px 25px 10px 15px; color:#08394e; }
ul.event-list li a:hover { background:#1fa0d9; color:#fff; }

/* NOTIFICATION */
.notification { margin:5px 20px; position:relative; }
.notification .material-icons { margin-top:5px; }
.badge-count { position:absolute; display:block; background:#f47321; border-radius:50%; color:#fff; top:-3px; right:-10px; width:18px; height:18px; padding-top:1px; text-align:center; box-sizing:border-box; }

/* SEARCH */
.search-events { background:#fff; margin-right:20px; padding:10px 20px; border-radius:20px; transition:width 0.3s ease; }
.search-events input { border:none; outline:none; transition:all 0.3s ease; width:100px; }
.search-events input:focus { width:200px; }
.search-events>* { vertical-align:middle; }

/* USER SECTION */
.user-section { position:relative; padding:10px; border:1px solid transparent; border-bottom:none; cursor:default; }
.user-section:hover { background:#fff; border:1px solid #ccc; border-bottom:none; box-shadow:0 5px 10px rgba(0,0,0,0.2); }
.user-section:hover .user-section-settings { display:block; }
.user-section:hover .material-icons:nth-child(3) { transform:rotate(180deg); }
.user-section .material-icons { transition:transform 0.2s ease; }
.user-section-label { margin:0 10px; min-width:82px; display:inline-block; }
.user-section>* { vertical-align:middle; }
.user-section-settings { display:none; margin:0; padding:0; position:absolute; top:100%; right:-1px; background:#fff; min-width:100%; border:1px solid #ccc; border-top:none; box-shadow:0 5px 10px rgba(0,0,0,0.2); box-sizing:content-box; }
.user-section-settings li { list-style:none; margin:0; padding:0; }
.user-section-settings li a { display:block; padding:10px 15px; white-space:nowrap; }
.user-section-settings li a:hover { background:#f47321; color:#fff; }
.user-section-settings .material-icons { vertical-align:middle; margin-right:12px; }
.user-section-settings li a:hover .material-icons { color:#fff; }

/* NAV */
nav.side-menu { position:fixed; top:54px; left:0; overflow:hidden; background:#08394e; height:100%; width:54px; transition:width 0.15s ease; z-index:8; overflow: auto; }
nav.side-menu a.item:first-child { padding:7px 10px; }
nav.side-menu a.item { display:block; text-align:center; padding:7px 10px; }
nav.side-menu a.item:hover { background:#1fa0d9; }
nav.side-menu a.item.active { background:#1fa0d9; }
nav.side-menu .material-icons { color:#fff; padding:9px 0; }
nav.side-menu .item-label { display:none; color:#fff; font-size:0.82em; }
nav.side-menu:hover { width:76px; box-shadow:0 0 10px rgba(0,0,0,0.3); }
nav.side-menu:hover .material-icons { padding:0; }
nav.side-menu:hover .item-label { display:block; }

/* MAIN */
.main { margin-left:44px; }

/* TOP */
.top { display:flex; justify-content:space-between; align-items:center; background:#313131; width:100%; padding:9px 20px; box-sizing:border-box; }
.top h1 { color:#ccc; font-weight:normal; font-size:32px; letter-spacing:1px; }
.top-info { display:inline-block; background:#23b400; color:#fff; font-size:0.82em; padding:5px 10px; border-radius:7px; }

/* CONTENT */
.content { padding:20px; min-height:calc(100vh - 54px); box-sizing:border-box; }
h1, h2, h3, h4, h5 { padding:0; margin:0; font-weight:normal; color:#08394e; }
a { text-decoration:none; color:#08394e; }

/* Columns */
.col-group {}
.col-group:after { content:""; display:block; clear:both; }
.col-group .col { box-sizing:border-box; }
.col-group .col { padding-left:10px; padding-right:10px; }
.col-group .col:first-child { padding-left:0; padding-right:10px; }
.col-group .col:last-child { padding-left:10px; padding-right:0; }
.col-2 { float:left; width:50%; }
.col-3 { float:left; width:33%; }

/* Table */
.tbl { width:100%; }
.tbl th { background:#707070; color:#fff; padding:5px; font-weight:normal; text-transform:uppercase; font-size:0.9em; white-space:nowrap; }
.tbl th a { color:#fff; }
.tbl th a:hover { color:#f79658; }
.tbl th .sort-icon { font-size:16px; margin-top:-3px; vertical-align:middle; color:#fff; }
.tbl td { padding:12px; font-size:0.9em; }
.tbl td.tbl-date { width:95px; }
.tbl td.tbl-time { width:135px; }
.tbl-title { text-align:center; background:#f79658; color:#fff; text-transform:uppercase; font-weight:normal; padding:9px; margin:10px 0; }
.tbl .material-icons.status { padding: 9px 0 8px; }
.tbl-pagination { margin-top:10px; }

/* Table Dark */
.tbl.tbl-dark  tr:nth-child(odd) { background:#cbcbcb; }
.tbl.tbl-dark  tr:nth-child(even) { background:#f0f0f0; }
.tbl.tbl-dark.-has-hover tr:hover { background:#08394e; }
.tbl.tbl-dark.-has-hover tr:hover td { color:#fff; }
.tbl.tbl-dark.-has-hover tr:hover td.tbl-sched .sched-txt { color:#fff; }
.tbl.tbl-dark tr.active { background:#08394e; }
.tbl.tbl-dark tr.active td { color:#fff; }
.tbl.tbl-dark tr.active td.tbl-sched .sched-txt { color:#fff; }
.tbl.tbl-dark td.tbl-sched { max-width:0; }
.tbl.tbl-dark td.tbl-sched .sched-txt { display:block; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; margin-right:5px; }

/* Table White */
.tbl.tbl-white tr { background:#fff; }
.tbl.tbl-white td { border-bottom:1px solid #e2e2e2; }
.tbl.tbl-white.-has-hover tr:hover { background:#fdece0; }
.tbl.tbl-white.-has-hover tr:hover td { color:#08394e; }

/* Table Hover */
.tbl.-has-hover tr { transition:all 0.1s ease; }
.tbl.-has-hover tr:hover .-show-on-hover { display:inline-block; }
.tbl.-has-hover .-show-on-hover { display:none; }
.tbl.-has-hover tr:hover .-hide-on-hover { display:none; }
.tbl.-has-hover .-hide-on-hover { display:inline-block; }

/* Buttons */
.btn { font-family:'Lato', sans-serif; padding:10px 17px; border:none; border-radius:5px; cursor:pointer; }
.btn:hover { opacity:0.9; }
.btn:disabled { background:#919191; }
.btn-orange { background-color:#f47321; color:#fff; }
.btn-blue { background-color:#009ddc; color:#fff; }
.btn-green { background-color:#23b400; color:#fff; }
.btn-red { background-color:#d50303; color:#fff; }
.btn-gray-dash { border:2px dashed #e2e2e2; color:#9e9e9e; }
.btn-small { font-size:12px; padding:7px 14px; }
.btn .material-icons { color:inherit; }
a.btn { text-align:center; }

/* Status Colors */
.status-red { color:#ff2242; }
.status-orange { color:#ff8041; }
.status-blue { color:#0188fe; }
.status-yellow { color:#ffba27; }
.status-medaqua { color:#00c39f; }
.status-green { color:#23b400; }

/* Sections */
section.section { display:block; margin:10px 0; padding:10px 15px; border-radius:10px; }
section.section .section-title { display:flex; justify-content:space-between; white-space:nowrap; }
section.section h2 { font-weight:normal; font-size:20px; }
section.section h2:after { content:""; display:block; width:100px; border-bottom:7px solid; }
section.section.type1 { background:#f0f0f0; }
section.section.type2 { background:#fff; border:1px solid #dcdcdc; }
section.section .section-content { padding:20px 0 5px; }
section.type3 { display:flex; }
section.type3>div, section.type3 .section-content { padding:10px; }
section.type3>div:first-child { padding-left:0; }
section.type3 .section-title { padding-bottom:20px; }

/* Satisfaction */
.satisfaction-icon { display:inline-block; text-align:center; margin:0 10px; }
.satisfaction-icon .material-icons { font-size:52px; }
.satisfaction-score { display:block; text-align:center; color:#919191; }
.satisfaction-score .font-small { color:#919191; }

/* Speaker */
.speaker-rating-row { display:flex; justify-content:space-between; align-items:center; margin-bottom:10px; }
.speaker-rating-row:last-child { margin-bottom:0; }
.speaker-rating-row.no-rating { opacity:0.6; }
.speaker-user-img { width:54px; height:54px; background:#cbcbcb; border-radius:50%; text-align:center; }
.speaker-user-img .material-icons { font-size:50px; color:#919191; }
.speaker-name { flex:1; margin-left:20px; }
.speaker-stars { flex:1; font-size:0.82em; color:#919191; text-align:center; }
.speaker-stars .material-icons { color:#f47321; }
.speaker-details { flex:1; font-size:0.82em; color:#919191; }
.speaker-comment { flex:2.5; color:#919191; }

/* Title Styles */
.title-fancy { font-weight:normal; }
.title-fancy .emp { color:#f47321; font-size:42px; font-weight:bold; }
.title-fancy .font-huge { font-size:46px; }
.title-fancy-sub { font-weight:normal; margin-left:50px; }

/* Dash Blocks */
.dash-blocks { border:2px dashed #e2e2e2; color:#9e9e9e; padding:20px; border-radius:12px; }

/* Tab */
.tab {}
.tab a, .tab label { display:inline-block; padding:10px 30px 0 0; font-size:1.2em; white-space:nowrap; cursor:pointer; }
.tab a:after, .tab label:after { content:""; display:block; border-bottom:3px solid; width:0; margin-top:5px; transition:width 0.2s ease; }
.tab a:hover:after, .tab label:hover:after { width:100%; }
.tab a.active, .tab label.active { color:#08394e; }
.tab a.active:after, .tab label.active:after { content:""; display:block; border-bottom:3px solid; width:100%; margin-top:5px; }
.tab.tab-gray a, .tab.tab-gray label { color:#9e9e9e; }
.tab.tab-blue a, .tab.tab-blue label { color:#1fa0d9; text-transform:uppercase; font-size:0.9em; }
.tab-contents .tab-cont { display:none; padding:20px; margin:auto; }

/* Tab About Page */
#tab-event-desc:checked ~ .tab label[for="tab-event-desc"]:after, 
#tab-banner:checked ~ .tab label[for="tab-banner"]:after, 
#tab-header:checked ~ .tab label[for="tab-header"]:after, 
#tab-event:checked ~ .tab label[for="tab-event"]:after, 
#tab-camera:checked ~ .tab label[for="tab-camera"]:after, 
#tab-colors:checked ~ .tab label[for="tab-colors"]:after { width:100%; }
#tab-event-desc:checked ~ .tab-contents .tab-event-desc, 
#tab-banner:checked ~ .tab-contents .tab-banner, 
#tab-header:checked ~ .tab-contents .tab-header, 
#tab-event:checked ~ .tab-contents .tab-event, 
#tab-camera:checked ~ .tab-contents .tab-camera, 
#tab-colors:checked ~ .tab-contents .tab-colors { display:block; }
.tab-contents .tab-event-desc { max-width:600px; padding:40px 0; }
.tab-contents .tab-banner { width:400px; }
.tab-contents .tab-header { width:400px; }
.tab-contents .tab-event { width:400px; }
.tab-contents .tab-camera { width:900px; }
.tab-contents .tab-colors { width:900px; }

/* Display Frame and Thumbnail */
.display-frame { height:70px; }
.display-frame img { width:auto; height:100%; }
.display-frame-thumbnail { height:70px; }
.display-frame-thumbnail img { width:auto; height:100%; }

/* Forms */
.ant-form.ant-form-vertical { display:flex; flex-wrap:wrap; }
.ant-form.ant-form-vertical .fld-item { margin-bottom:15px; width:100%; }
.ant-form.ant-form-vertical .fld-item label { padding-bottom:5px; display:block; }
.ant-form.ant-form-vertical .fld-item .ant-btn-primary { font-family:'Lato', sans-serif; padding:7px 14px; border:none; border-radius:5px; cursor:pointer; height:auto; background-color:#009ddc; }
.ant-form.ant-form-vertical .fld-item .ant-btn-primary span { color:#fff; }
.ant-form.ant-form-vertical .fld-item.fld-item-col-2 { width:50%; padding:0 20px 0 0; box-sizing:border-box; }
.ant-form.ant-form-vertical .fld-item.fld-item-col-2:nth-child(even) { padding:0 0 0px 0; }
.ant-form.ant-form-vertical .fld-item .ant-time-picker, .ant-form.ant-form-vertical .fld-item .ant-calendar-picker { width:100%; }
.fld-large { padding:15px 20px; margin-bottom:10px; border-radius:5px; border:1px solid #9e9e9e; }

/* Checkbox*/
.checkbox-icon:before { content:"\e835"; font-family:"Material Icons"; font-size:22px; vertical-align:middle; display:inline-block; margin-top:-3px; }
input[type="checkbox"]:checked + label .checkbox-icon:before { content:"\e834"; font-family:"Material Icons"; color:#23b400; }

/* Color Picker */
.color-picker-item { margin:5px; }
.color-picker-item .circle-item { margin-right:10px; }
.color-picker-item a { display:inline-block; padding:15px; width:250px; box-sizing:border-box; }
.circle-item { display:inline-block; border-radius:50%; background:#f47321; color:#fff; width:28px; height:28px; vertical-align:middle; padding:6px 0; box-sizing:border-box; text-align:center; }
.color-picker-box { display:inline-block; width:22px; height:22px; border:1px solid #919191; vertical-align:middle; margin-bottom:3px; }

/* Spacing */
.v-space-small { padding-top:5px; padding-bottom:5px; }
.v-space-med { padding-top:10px; padding-bottom:10px; }
.v-space-large { padding-top:20px; padding-bottom:20px; }
.h-space-small { padding-left:5px; padding-right:5px; }
.h-space-med { padding-left:10px; padding-right:10px; }
.h-space-large { padding-left:20px; padding-right:20px; }

/* Margins */
.v-margin-large { margin-top:20px; margin-bottom:20px; }
.h-margin-small { margin-left:5px; margin-right:5px; }
.h-margin-med { margin-left:10px; margin-right:10px; }

/* Misc */
.hide { display:none; }
.full-width { width:100%; display:block; box-sizing:border-box; }
.half-width { width:50%; display:block; box-sizing:border-box; }
.uppercase { text-transform:uppercase; }
.line-height-med { line-height:1.5em; }
.text-align-left { text-align:left; }
.text-align-center { text-align:center; }
.text-align-right { text-align:right; }
.text-gray { color:#919191; }
.text-light-gray { color:#cbcbcb; }
.font-small { font-size:0.82em; }
.font-med { font-size:1.3em; }
.font-bold { font-weight:bold; }
.page-placeholder { padding-top:54px; }
.nowrap { white-space:nowrap; }
.v-align { vertical-align:middle; }
.flex-wrap { flex-wrap:wrap; }
.space-between { display:flex; justify-content:space-between; align-items:center; }
.align-items-stretch { align-items:stretch; }
.align-items-start { align-items:start; }
.align-items-center { align-items:center; }



/* Override Fixes */
#login-cont .ant-form-item { margin:0; }
.btn-orange span, .btn-blue span, .btn-green span, .btn-red span { color:#fff; }
.btn-gray-dash-add { background:none; border:2px dashed #e2e2e2; padding:7px; height:auto; margin-bottom:10px; }
.btn-gray-dash-add span { color:#9e9e9e; font-size:1.2em; }
.btn-gray-dash-add span:before { content:"\e145"; display:inline-block; font-family:"Material Icons"; vertical-align:middle; font-size:22px; margin-top:-5px; margin-right:5px; }
.btn-gray-dash-add:hover { background:#e9e9e9; border:2px dashed #e2e2e2; }
.btn-gray-dash-add:focus { background:rgba(24,144,255,0.7); border-style:solid; }
.btn-gray-dash-add:focus span { color:#fff; }
.ant-table-column-sorter path { color:#fff; opacity:0.2; }
.ant-table-column-sorter .on path { opacity:1; }
.ant-table-thead tr th, .ant-table-thead>tr>th.ant-table-column-sort, .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover { background:#707070; border:none; }
.ant-table-thead tr th>div { color:#fff; }
.ant-table-wrapper .ant-pagination-prev a, .ant-table-wrapper .ant-pagination-next a { padding-top:8px; }
.ant-pagination-item-active { background:rgba(24,144,255,0.7); }
.ant-pagination-item-active a { color:#fff; }
.ant-pagination-item-link .material-icons { padding-top:3px; }
.ant-select-selection-selected-value { line-height:30px; }
.ant-select-selection__rendered
{
   white-space: nowrap;
   height: 30px;
   overflow: auto
}

/* Modal */
.ant-modal-header { border:none; }
.ant-modal-header .ant-modal-title { font-size:24px; color:#08394e; }



/* Animations */
.slide-up { transform:translateY(10px); animation:slide-up 0.3s ease forwards; opacity:0; }
@keyframes slide-up { to{ transform:translateY(0); opacity:1; } }



/* LOGIN */
#login-area { display:flex; align-items:center; min-height:100vh; justify-content:flex-end; background:url(./images/homescreen_bg.png) no-repeat left center; background-size:50%; }
#login-cont { width:50%; text-align:center; transition:all 0.3s ease; }
#login-form { padding:40px 0 5px 0; }
#login-cont .eesy-logo { height:90px; background:url(./images/EESY_logo_big.png) no-repeat center; background-size:contain; }
#login-cont .ganapp-logo { margin-bottom:10px; margin:auto; height:100px; width:100px; background:url(./images/GanApp_logo@2x.png) no-repeat center; background-size:cover; }
#login-cont .dl-btns { margin-top:10px; }
#login-cont .dl-btns img { height:50px; width:auto; }
#login-cont .btn { width:111px; text-transform:uppercase; display:inline-block; margin:0 3px; }
#login-cont .fld { margin-bottom:10px; }
#login-cont .fld-text input { background:#f0f0f0; border:1px solid #f0f0f0; border-radius:5px; padding:7px 12px; width:230px; outline:none; box-sizing:border-box; }
#login-cont .fld-text input:hover { border:1px solid #9e9e9e; }
@media screen and (max-width:870px) {
	#login-area { justify-content:center; background-size:cover; }
	#login-cont { background:rgba(255,255,255,0.8); padding:30px; border-radius:5px; width:auto; }
}



/* Responsive */
@media screen and (max-width:960px) {
	.col-group .col-2, .col-group .col-2:first-child, .col-group .col-2:last-child { width:100%; padding-left:0; padding-right:0; }
}
@media screen and (max-width:820px) {
	header .user-section-label { display:none; }
	header .search-events input:focus { width:100px; }
}
@media screen and (max-width:710px) {
	header { padding:5px 10px; }
	header .eesy-logo { margin-right:10px; width:38px; overflow:hidden; }
	header .search-events  { margin-right:10px; }
	header .search-events input  { width:0; }
	header .search-events:hover { padding:10px 20px; border-radius:20px; }
	header .search-events:hover input { width:100px; }
	header .search-events input:focus { width:100px; }
}
@media screen and (max-height:550px) {
	nav.side-menu:hover { width:110px; box-shadow:0 0 10px rgba(0,0,0,0.3); }
	nav.side-menu a.item { text-align:left; padding:0 10px; width:110px; }
	nav.side-menu:hover .material-icons { padding:9px 5px 9px 0; vertical-align:middle; }
	nav.side-menu:hover .item-label { display:inline-block; }
}
